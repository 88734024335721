.c-container {
    @apply .max-w-7xl .mx-auto .px-10;

    @screen lg {
        @apply .px-16;
    }

    &--xl {
        @apply .max-w-8xl;
    }

    &--md {
        @apply .max-w-6xl;
    }
}