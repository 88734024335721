.c-hero {
    $c: &;

    @apply .relative;

    &__image {
        @apply .w-full .object-cover .h-56;

        @screen md {
            @apply .h-auto;
        }
    }

    &__title {
        @apply .text-7xl;
    }

    &__heading {
        @apply .relative .pb-6;

        @screen lg {
            @apply .pb-10;
        }

        @screen xl {
            @apply .pb-16;
        }

        &:after {
            content: '';

            @apply .bg-primary .h-1 .absolute .bottom-0 .left-0 .w-20;

            @screen lg {
                @apply .w-40;
            }

            @screen xl {
                @apply .w-80;
            }
        }
    }
}