.c-heading {
    $c: &;

    @apply .mb-12;

    @screen md {
        @apply .mb-20;
    }

    @screen lg {
        @apply .mb-32;
    }

    &__top {
        @apply .text-center;
    }

    &__bottom {
        @apply .mt-12;

        @screen md {
            @apply .mt-20;
        }
    }
}