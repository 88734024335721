.c-form {
    $c: &;

    @apply .text-0;

    &__rows {
        @apply .-mb-4;
    }

    &__row {
        @apply .pb-4;
    }

    &__columns {
        @apply .-mb-4;
    }

    &__column {
        @apply .pb-4;
    }
}