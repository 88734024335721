.c-content-builder {
    $c: &;

    @apply .text-0;

    &__rows {
        @apply .-mb-10;

        @screen md {
            @apply .-mb-16;
        }

        @screen lg {
            @apply .-mb-20;
        }
    }

    &__row {
        @apply .pb-10;

        @screen md {
            @apply .pb-16;
        }

        @screen lg {
            @apply .pb-20;
        }
    }
}