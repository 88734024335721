.c-cookie-consent {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    left: 0;

    transform: translateY(100%);

    $c: &;

    @apply .bg-primary .text-center .p-4 .transform .translate-y-full .transition-all .duration-500 .ease-in-out;

    &__content {
        @screen md {
            @apply .inline-flex .items-center;
        }
    }

    &__message {
        @apply .text-sm .leading-normal .mb-4 .text-white;

        @screen md {
            @apply .mr-4 .mb-0;
        }

        a {
            @apply .text-white .border-b .border-transparent .leading-tight .font-semibold;

            &:hover,
            &:active {
                @apply .border-white;
            }
        }
    }

    &.in-view {
        @apply .translate-y-0;
    }
}
