.c-fase {
    $c: &;

    @apply .flex .flex-col .relative .text-0;

    @screen lg {
        @apply .flex-row;
    }

    &__figure {
        @apply .relative .pb-10 .text-center .mx-auto;

        @screen lg {
            @apply .pb-16 .w-80;
        }
    }

    &__image {
        @apply .inline-block .z-50 .relative .rounded-full .w-48 .h-48 .object-cover;

        @screen lg {
            @apply .w-24 .h-24;
        }
    }

    &:after {
        @screen lg {
            content: '';

            left: 50%;
    
            transform: translateX(-50%);
    
            @apply .absolute .bottom-0 .w-px .h-full .bg-secondary-lightest;
        }
    }

    &.is-large {
        #{$c} {
            &__image {
                @apply .w-48 .h-48;
            }
        }
    }

    &.is-last {
        &:after {
            @apply .hidden;
        }

        #{$c} {
            &__figure {
                @screen lg {
                    @apply .pb-0;
                }
            }
        }
    }

    &.is-even {
        @apply .flex-col-reverse;

        @screen lg {
            @apply .flex-row;
        }
    }
}