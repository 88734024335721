.c-filter {
    $c: &;

    @apply .p-3.5 .min-w-base .inline-flex .justify-center .w-full .border .border-secondary-lightest .bg-transparent;

    &__label {
        @apply .lowercase .text-sm .text-grey .font-primary-book .leading-relaxed .transition-all .duration-300 .ease-in-out;
    }

    &:hover,
    &:active {
        @apply .border-primary .bg-primary;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }
    }
    
    &.is-selected {
        @apply .border-primary .bg-primary;

        #{$c} {
            &__label {
                @apply .text-white .font-primary-medium;
            }
        }
    }
}