.c-mosaic {
    $c: &;

    @apply .text-0 .relative .z-30;

    &__list {
        @apply .flex .flex-wrap .-ml-10 .-mb-10;
    }

    &__item {
        @apply .w-full .pl-10 .pb-10;

        @screen md {
            @apply .w-1/2;
        }
    }
}