.c-pagination {
    $c: &;

    @apply .text-0 .flex .-ml-8;

    @screen md {
        @apply .-ml-16;
    }

    &__item {
        @apply .pl-8 .flex .items-center;

        @screen md {
            @apply .pl-16;
        }
    }
}