// -----------------------------------------------------------------------------
// vendors/slick-carousel
// -----------------------------------------------------------------------------

$slick-font-path: "";
$slick-font-family: "";
$slick-loader-path: "../images/vendor/";
$slick-arrow-color: white;
$slick-dot-color: black;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "\2190";
$slick-next-character: "\2192";
$slick-dot-character: "\2022";
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';


.slick-slider {
    .slick-list,
    .slick-track {
        @apply .h-full;
    }

    &.slick-dotted {
        @apply .mb-0;
    }
    
    .slick-slide {
        &:focus {
            @apply .outline-none;
        }
    }
}