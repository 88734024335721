.c-file-upload {
    $c: &;

    @apply .w-full;

    @screen md {
        @apply .flex;
    }

    &__input {
        @apply .w-0 .h-0 .opacity-0 .text-0 .leading-none .invisible .m-0 .p-0;
    }

    &__value {
        @apply .flex-grow .mr-4;
    }

    &__label {
        @apply .flex-shrink-0 .mt-3 .w-full;

        @screen md {
            @apply .mt-0 .w-auto;
        }
    }
}