.c-featured-block {
    $c: &;

    @apply .relative .flex .flex-wrap;

    &__figure {
        @apply .w-full;

        @screen md {
            @apply .w-1/2;
        }
    }

    &__image {
        @apply .w-full;

        @screen md {
            @apply .w-auto;
        }
    }

    &__watermark {
        z-index: -1;
        
        @apply .absolute .top-0 .right-0;
    }

    &__content {
        @apply .w-full .py-8;

        @screen md {
            @apply .w-1/2 .pl-16;
        }

        @screen lg {
            @apply .pl-20;
        }

        @screen xl {
            @apply .pl-40;
        }
    }

    &__heading {
        @apply .mb-12;
    }

    &__links {
        @apply .mt-10 .-mb-3;

        @screen lg {
            @apply .flex .-ml-10;
        }
    }

    &__link {
        @apply .pb-3;

        @screen lg {
            @apply .pl-10;
        }
    }

    &__scroll-down {
        @apply .hidden;

        @screen xl {
            left: 100%;

            animation: arrow-bounce 1s infinite;

            @apply .block .absolute .w-full .ml-16 .mb-40 .bottom-0 .mr-40 .pb-16;

            a {
                transform: rotate(90deg);
            }
        }
    }

    &.is-reversed {
        @apply .flex-row-reverse;

        #{$c} {
            &__content {
                @apply .pl-0;

                @screen md {
                    @apply .pr-16;
                }

                @screen lg {
                    @apply .pr-20;
                }

                @screen xl {
                    @apply .pr-40;
                }
            }
        }
    }

    &.has-large-offset {
        #{$c} {
            &__content {
                @screen lg {
                    @apply .py-16;
                }
            }
        }
    }

    &.is-centered {
        @screen xl {
            @apply .items-center;
        }
    }

    &.is-bottom {
        @screen xl {
            @apply .items-end;
        }

        #{$c} {
            &__content {
                @apply .pb-0;
            }
        }
    }

    &.has-watermark {
        @apply .pt-32;
    }

    &.is-intro {
        #{$c} {
            &__figure {
                @apply .relative;
            }

            &__image {
                @screen md {
                    @apply .absolute .h-full .object-cover;
                }
            }

            &__content {
                @screen xl {
                    @apply .mb-40;
                }
            }
        }
    }
}

@keyframes arrow-bounce {
    0% {
    }
    50% {
        transform: translateY(-16px);
    }
    100% {
        transform: translateY(0);
    }
}
