.c-button {
    $c: &;

    @apply .p-3.5 .min-w-base .inline-flex .justify-center .cursor-pointer;

    &__label {
        @apply .text-sm .leading-relaxed .font-primary-medium .lowercase;
    }
    
    &--primary {
        @apply .bg-primary;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }

        &:hover,
        &:active {
            @apply .bg-primary-light;
        }
    }

    &--secondary {
        @apply .bg-secondary-lighter;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }

        &:hover,
        &:active {
            @apply .bg-secondary-light;
        }
    }

    &--tertiary {
        @apply .bg-tertiary;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }

        &:hover,
        &:active {
            @apply .bg-tertiary-light;
        }
    }

    &--sm {
        @apply .p-2.5;
    }
}