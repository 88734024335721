.c-content {
    $c: &;

    @apply .overflow-hidden;

    &__section {
        @apply .relative;

        &--realization-detail-top {
            .description {
                @apply .relative;

                @screen md {
                    @apply .bg-tertiary-alpha-15;
                }

                &:after,
                &:before {
                    @screen md {
                        content: '';

                        @apply .absolute .bg-tertiary-alpha-15 .w-full .top-0 .bottom-0;
                    }
                }

                &:after {
                    left: 100%;
                }
        
                &:before {
                    right: 100%;
        
                    @apply .w-10;

                    @screen lg {
                        @apply .w-40;
                    }

                    @screen xl {
                        @apply .w-80;
                    }
                }
            }
        }

        &--realization-detail-bottom {
            &.has-bg {
                &:after {
                    @screen md {
                        content: '';
    
                        @apply .absolute .w-3/5 .bg-tertiary-alpha-15 .h-60 .right-0 .top-0;
                    }
                }
            }
        }

        &--contact-top {
            .left-col {
                @apply .relative;

                &:before {
                    content: '';
    
                    right: 100%;
                    width: 5000px;
    
                    @apply .bg-primary .absolute .top-0 .bottom-0;
                }

                &:after {
                    content: '';
    
                    left: 100%;
                    width: 5000px;
    
                    @apply .bg-primary .absolute .top-0 .bottom-0;

                    @screen md {
                        @apply .hidden;
                    }
                }
            }
            
            .map {
                @apply .relative;

                &:before {
                    @screen md {
                        content: '';
    
                        right: 100%;
                        width: 5000px;
                        height: 50%;
        
                        @apply .bg-primary .absolute .top-0;
                    }
                }
            }
        }

        &--about-intro {
            &:after {
                @screen xl {
                    content: '';

                    z-index: -1;
    
                    @apply .absolute .bottom-0 .left-0 .right-0 .h-40 .bg-tertiary-alpha-15;
                }
            }
        }

        &--home-realizations {
            &:after {
                content: '';

                z-index: -1;

                @apply .absolute .bg-primary-dark .top-0 .right-0 .left-auto .bottom-0 .w-1/2;
            }
        }
    }
}