.c-filters {
    $c: &;

    @apply .text-0;

    &__list {
        @apply .flex .flex-wrap .-ml-8 .-mb-3;

        @screen md {
            @apply .-mb-8;
        }

        @screen lg {
            @apply .-ml-8;
        }

        @screen xl {
            @apply .-ml-16;
        }
    }

    &__item {
        @apply .w-full .pl-8 .pb-3;

        @screen md {
            @apply .w-auto .pb-8;
        }

        @screen lg {
            @apply .pl-8;
        }

        @screen xl {
            @apply .pl-16;
        }
    }
}