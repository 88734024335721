.c-footer {
    $c: &;

    @apply .text-0 .bg-primary-dark .py-16;

    .c-nav {
        &--social {
            .c-nav {
                &__link {
                    &:hover,
                    &:active {
                        .c-nav {
                            &__icon {
                                @apply .text-tertiary-light;
                            }
                        }
                    }
                }

                &__icon {
                    @apply .text-tertiary;
                }
            }
        }
    }
}