.c-fases {
    $c: &;

    &__list {

    }

    &__item {
        @apply .mb-12;

        @screen lg {
            @apply .mb-0;
        }

        &:last-child {
            @apply .mb-0;
        }
    }
}