.c-text {
    $c: &;

    @apply .text-sm;

    p {
        @apply .mb-5.5;

        &:last-child {
            @apply .mb-0;
        }
    }

    a {
        @apply .relative .text-secondary .pb-0.5 .leading-none;

        &:after {
            content: '';

            top: 100%;

            @apply .left-0 .right-auto .h-px .w-full .bg-secondary .absolute .transition-all .duration-300 .ease-in-out;
        }

        &:hover,
        &:active {
            &:after {
                @apply .w-0;
            }
        }
    }

    ul {
        @apply .mb-5.5;

        &:last-child {
            @apply .mb-0;
        }

        li {
            @apply .relative .pl-3;

            &:before {
                content: '•';

                @apply .absolute .left-0 .text-secondary .font-primary-book .text-sm .leading-looser;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply .text-2xl .leading-normal .mb-3;
    }

    &--alt {
        ul {
            @apply .mb-12;
    
            &:last-child {
                @apply .mb-0;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            @apply .font-primary-bold .text-primary .text-sm .leading-snug;
        }
    }
}