.c-page {
    $c: &;

    &.mobile-nav-open {
        @apply .fixed .w-full .h-full .overflow-hidden;

        .c-header {
            @apply .bg-transparent .shadow-none;

            .c-logo {
                @apply .opacity-0;
            }
        }
    }
}