.sold {
    .c-preview {
        &__body {
            opacity: 0.95;
        }

        &__figure {
            img {
                opacity: 0.95;
            }
        }
    }
}

.c-preview {
    $c: &;

    @apply .relative .text-0;

    &__sold {
        position: absolute;
        @apply .font-primary-bold .text-sm .leading-relaxed .text-primary .bg-primary;
        top: 0;
        left: 0;
        color: white;
        padding: 10px 20px;
    }

    &__figure {
        @apply .relative;
    }

    &__overlay {
        @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .w-full .h-full .bg-primary-alpha-80 .flex .justify-center .items-center .opacity-0 .transition-all .duration-300 .ease-in-out;
    }

    &__link {
        @apply .inline-block .text-white .text-sm .font-primary-book .leading-none .border-b .border-white .pb-1 .lowercase;
    }

    &__body {
        @apply .mt-6;
    }

    &__title {
        @apply .font-primary-bold .text-sm .leading-relaxed .text-primary;
    }

    &__location {
        @apply .inline-block .mt-0.5 .text-sm;
    }

    &:hover,
    &:active {
        #{$c} {
            &__overlay {
                @apply .opacity-100;
            }
        }
    }
}