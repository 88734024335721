.c-link {
    $c: &;

    @apply .inline-flex .text-0 .items-center;

    &__icon {
        @apply .inline-block .rounded-full .border .w-12 .h-12 .flex .items-center .justify-center .transition-all .duration-300 .ease-in-out;
    }

    &__icon-alt {
        @apply .inline-block;
    }

    &__label {
        @apply .inline-block .text-sm .lowercase .leading-none .transition-all .duration-300 .ease-in-out;
    }

    &--primary {
        #{$c} {
            &__icon {
                @apply .border-primary .text-primary;
            }

            &__icon-alt {
                @apply .text-primary;
            }

            &__label {
                @apply .relative .text-primary;

                &:after {
                    content: '';

                    top: 100%;

                    @apply .left-0 .right-auto .h-px .w-full .bg-primary .absolute .transition-all .duration-300 .ease-in-out .mt-1;
                }
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__icon {
                    @apply .bg-primary;

                    .c-icon {
                        @apply .text-white;
                    }
                }

                &__label {
                    &:after {
                        @apply .w-0;
                    }
                }
            }
        }

        &.is-disabled {
            @apply .cursor-default;
            
            #{$c} {
                &__icon {
                    @apply .border-secondary-lighter .text-secondary-lighter;
                }
            }

            &:hover,
            &:active {
                #{$c} {
                    &__icon {
                        @apply .border-secondary-lighter .bg-transparent;

                        .c-icon {
                            @apply .text-secondary-lighter;
                        }
                    }
                }
            }
        }
    }

    &--secondary {
        #{$c} {
            &__icon {
                @apply .border-tertiary .text-tertiary;
            }

            &__icon-alt {
                @apply .text-tertiary;
            }

            &__label {
                @apply .relative .text-tertiary;

                &:after {
                    content: '';

                    top: 100%;

                    @apply .left-0 .right-auto .h-px .w-full .bg-tertiary .absolute .transition-all .duration-300 .ease-in-out .mt-1;
                }
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__icon {
                    @apply .bg-tertiary;

                    .c-icon {
                        @apply .text-white;
                    }
                }

                &__label {
                    &:after {
                        @apply .w-0;
                    }
                }
            }
        }
    }

    &.is-inverted {
        #{$c} {
            &__label {
                &:after {
                    @apply .w-0;
                }
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    &:after {
                        @apply .w-full;
                    }
                }
            }
        }
    }

    &.has-icon-before {
        #{$c} {
            &__icon {
                @apply .mr-8;
            }

            &__icon-alt {
                @apply .mr-4;
            }
        }
    }

    &.has-icon-after {
        #{$c} {
            &__icon {
                @apply .ml-8;
            }

            &__icon-alt {
                @apply .ml-4;
            }
        }
    }
}