.c-form-field {
    $c: &;

    @apply .text-0;

    &__label {
        @apply .inline-block .text-sm .mb-2;

        &.is-required {
            &:after {
                content: '*';

                @apply .absolute .text-secondary .pl-1 .font-primary-book .text-base;
            }
        }
    }

    &__input {
        @apply .block .w-full .border .border-secondary-lightest .text-primary .py-3 .px-6 .text-sm .transition-all .duration-300 .ease-in-out .bg-transparent;
    }

    &--text,
    &--email,
    &--textarea {
        #{$c} {
            &__input {
                &:focus {
                    @apply .border-primary;
                }
            }
        }
    }

    &__errors {
        @apply .hidden;
    }

    &.has-errors {
        #{$c} {
            &__input {
                @apply .border-error;

                &::placeholder {
                    @apply .text-error;
                }
            }
        }
    }
}