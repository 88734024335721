.c-google-map {
    $c: &;

    @apply .h-80 .w-full;

    @screen md {
        @apply .h-120;
    }

    .gm-style {
        @apply .bg-primary;
    }

    .gm-style-cc {
        @apply .hidden;
    }
}