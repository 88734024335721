.c-previews {
    $c: &;

    @apply .text-0;

    &__list {
        @apply .flex .flex-wrap .-ml-10 .-mb-10;

        @screen xl {
            @apply .-ml-20 .-mb-20
        }
    }

    &__item {
        @apply .w-full .pl-10 .pb-10;

        @screen md {
            @apply .w-1/2;
        }

        @screen lg {
            @apply .w-1/3;
        }

        @screen xl {
            @apply .pl-20 .pb-20
        }
    }
}