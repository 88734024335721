.c-icon {
    @apply .fill-current .transition-all .duration-200 .ease-in;

    &--facebook {
        width: 8px;
        height: 15px;
    }

    &--instagram {
        width: 16px;
        height: 16px;
    }

    &--linkedin {
        width: 16px;
        height: 16px;
    }

    &--long-arrow {
        width: 20px;
        height: 13px;
    }
}