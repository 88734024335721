.c-quote {
    $c: &;

    &__icon {
        @apply .mb-6;
    }

    &__text {
        @apply .text-3xl .text-primary .font-secondary .leading-tighter;

        @screen md {
            @apply .text-4.5xl;
        }
    }

    &__author {
        @apply .block .font-primary-book .text-primary .text-sm .leading-relaxed .mt-8;

        @screen md {
            @apply .mt-10;
        }

        @screen lg {
            @apply .mt-12;
        }
    }

    &--centered {
        @apply .text-center;

        #{$c} {
            &__icon {
                @apply .mx-auto;
            }
        }
    }
}