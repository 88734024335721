.c-blog {
    $c: &;

    @apply .text-0 .w-full;

    &__figure {
        @apply .overflow-hidden;
    }

    &__image {
        @apply .transform .transition-all .duration-300 .ease-in-out;
    }

    &__title {
        @apply .leading-tight;
    }

    &__heading {

    }

    &__post-date {
        @apply .inline-block .mb-4;
    }

    &__text {
        @apply .mt-8;
    }

    &:hover,
    &:active {
        #{$c} {
            &__image {
                @apply .scale-105;
            }
        }
    }
}