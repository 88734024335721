.c-nav {
    $c: &;

    @apply .text-0;

    &__list {

    }

    &__item {

    }

    &__link {

    }

    &__label {
        @apply .inline-block .transition-all .duration-300 .ease-in-out;
    }

    &--primary {
        #{$c} {
            &__list {
                @apply .-ml-8 .-mb-8 .text-center;

                @screen md {
                    @apply .flex .text-left;
                }
            }

            &__item {
                @apply .pl-8 .pb-8;

                &.is-active {
                    #{$c} {
                        &__label {
                            &:after {
                                @apply .w-full;
                            }
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            &:after {
                                @apply .w-full;
                            }
                        }
                    }
                }
            }

            &__label {
                @apply .relative .text-lg .font-primary-book .text-tertiary .lowercase .leading-none;

                @screen md {
                    @apply .text-base .text-primary;
                }

                &:after {
                    content: '';

                    top: 100%;

                    @apply .w-0 .h-px .bg-black .absolute .left-0 .right-auto .mt-1 .transition-all .duration-300 .ease-in-out;
                }
            }
        }
    }

    &--secondary {
        #{$c} {
            &__list {
                @apply .flex .-ml-6 .justify-center;

                @screen md {
                    @apply .justify-start;
                }
            }

            &__item {
                @apply .pl-6;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-secondary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xsm .font-primary-book .text-tertiary .lowercase .leading-none;

                @screen md {
                    @apply .text-grey;
                }
            }
        }
    }

    &--social {
        #{$c} {
            &__list {
                @apply .flex .-ml-6 .items-end .justify-center;

                @screen md {
                    @apply .justify-start;
                }
            }

            &__item {
                @apply .pl-6;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__icon {
                            @apply .text-secondary;
                        }
                    }
                }
            }

            &__icon {
                @apply .text-tertiary;

                @screen md {
                    @apply .text-secondary-lighter;
                }
            }
        }
    }

    &--footer {
        #{$c} {
            &__list {
                @apply .flex .flex-wrap .-ml-10 .-mb-8 .justify-center;

                @screen md  {
                    @apply .justify-start;
                }
            }

            &__item {
                @apply .pl-10 .pb-8;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            &:after {
                                @apply .w-full;
                            }
                        }
                    }
                }
            }

            &__label {
                @apply .relative .text-xsm .text-tertiary .leading-none .font-primary-book .lowercase;

                &:after {
                    content: '';

                    top: 100%;

                    @apply .w-0 .h-px .bg-tertiary .left-0 .right-auto .absolute .mt-0.5 .transition-all .duration-300 .ease-in-out;
                }
            }
        }
    }

    &--legal {
        #{$c} {
            &__list {

            }

            &__item {

            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-tertiary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xs .text-tertiary-alpha-70 .lowercase .font-primary-book .leading-none;
            }
        }
    }

    &--breadcrumb {
        #{$c} {
            &__list {
                @apply .flex;
            }

            &__item {
                &:last-child {
                    #{$c} {
                        &__label {
                            @apply .text-grey;
                        }
                    }

                    &:after {
                        @apply .hidden;
                    }
                }

                &:after {
                    content: '>';

                    @apply .font-primary-book .text-secondary-lighter .text-sm .px-1.5;
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-grey;
                        }
                    }
                }
            }

            &__label {
                @apply .text-sm .text-secondary-lighter .lowercase;
            }
        }
    }
}